import React from 'react'

import Button from '../shared/components/Button'
// import HeroImage from '../../svg/HeroImage'
import Rocket from '../../svg/rocketImage.svg'

const FirstSection = ({ id }) => {
  return (
    <section id={id} className="flex items-center justify-center lg:h-semifull mt-8">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <p className="mt-4 mb-2 text-primarygray">F&gt;sterWeb.io</p>

          <h1>Make your website load incredibly fast</h1>
          <p className="mt-6">
            We’re the first company to help you get a sub-second page load on mobile, whatever your
            technology stack.
          </p>
          <p className="mt-6">Are you obsessed with speed?</p>
          <p className="my-8">
            <Button size="lg" navigateTo="learn-more">
              Learn More
            </Button>
          </p>
        </div>
        <div className="lg:w-1/2 pl-24">
          <img src={Rocket} alt="Fasterweb Banner" className="w-full" />
        </div>
      </div>
    </section>
  )
}

export default FirstSection
