import React from 'react'
import Button from '../shared/components/Button'

const CTASection = ({ title, primaryText, buttonText, navigateTo }) => {
  return (
    <div>
      <section className="flex justify-center items-center mx-auto px-8 py-24 rounded-lg text-center bg-gray-100">
        <div className="container">
          <h2>{title}</h2>
          <div className="mt-4">{primaryText}</div>
          <div className="mt-8">
            <Button size="xl" navigateTo={navigateTo}>
              {buttonText}
            </Button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CTASection
