import React from 'react'

import Layout from '../components/shared/components/Layout'
import SplitSection from '../components/shared/components/SplitSection'
import CTAImage1 from '../svg/CTAImage1.svg'
import CTAImage2 from '../svg/CTAImage2.svg'
import FirstSection from '../components/IndexPage/FirstSection'
import CTASection from '../components/IndexPage/CTASection'
import Button from '../components/shared/components/Button'
import SEO from '../components/Seo'
// import TestimonialSection from '../components/IndexPage/TestimonialSection'
// import PricingSection from '../components/IndexPage/PricingSection/PricingSection'

const IndexPage = () => (
  <Layout>
    <SEO />
    <FirstSection id="heroImage" />

    <section className="flex justify-center items-center mx-auto px-8 py-24 rounded-lg text-center bg-gray-100">
      <div className="container">
        <h2>Why web performance matters?</h2>
        <h3>
          Keep your site visitors engaged. Engaged visitors mean they visit more pages, stay longer
          and buy more.
        </h3>
        <ul className="mt-4">
          <li>&#10004; More page views</li>
          <li>&#10004; More conversions</li>
        </ul>

        <p>&nbsp;</p>
        <p>
          What does Google say about{' '}
          <a
            style={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://developers.google.com/web/fundamentals/performance/why-performance-matters"
          >
            why performance matters.
          </a>
        </p>
      </div>
    </section>

    <SplitSection
      id="getStarted"
      primarySlot={
        <div className="lg:pr-4 xl:pr-4">
          <h2>How does it work?</h2>
          <p className="my-8">
            After the first page load, we intelligently prefetch the most likely links on your site
            that a user will click on. These pages load INSTANTLY (less than 200 milliseconds).
          </p>
          <Button navigateTo="learn-more">Learn More</Button>
        </div>
      }
      secondarySlot={<img src={CTAImage1} alt="Fasterweb Banner" className="w-full" />}
    />

    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-4 xl:pl-4">
          <h2>How to get started?</h2>
          <p className="my-8">
            We’re currently working at capacity and our service is not yet ready for everyone but we
            will work with a few select clients.
          </p>
          <p className="my-8">Are you obsessed with speed?</p>
          <Button navigateTo="contact">Request an Invite</Button>
        </div>
      }
      secondarySlot={<img src={CTAImage2} alt="Fasterweb Banner" className="w-full" />}
    />

    <CTASection
      title={<>Why now?</>}
      primaryText={
        <>
          <h3>
            You could be one of the first, before your competitors and before this technology is
            available to everyone.
          </h3>
          <p>We’re looking for sites that are relatively simple and beautiful.</p>
          <p>
            Sites that we can add to our portfolio to demonstrate to the world what a fast site
            looks like.
          </p>
          <p>&nbsp;</p>
          <h3>Do you qualify? </h3>
          <p>We’re looking for sites with:</p>
          <ul>
            <li>- relatively little Javascript (easier to implement)</li>
            <li>
              - more than 10K page visits per month (for intelligently predicting user behavior)
            </li>
          </ul>
        </>
      }
      buttonText="Request an Invite"
      navigateTo="contact"
    />

    {/* <TestimonialSection id="testimonials" /> */}

    {/* <PricingSection id="pricing" /> */}
  </Layout>
)

export default IndexPage
